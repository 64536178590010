import React from "react";

class JobFilterCheckBox extends React.Component {
  render() {
    return (
      <div key={this.props.name} className="custom-control custom-checkbox">
        <input
          className="custom-control-input"
          id={this.props.name}
          name={this.props.name}
          type="checkbox"
          checked={this.props.checked || false}
          onChange={this.props.onChange}
        />
        <label className="custom-control-label" htmlFor={this.props.name}>
          {this.props.label} <span className="text-muted">({this.props.count})</span>
        </label>
      </div>
    );
  }
}

export default JobFilterCheckBox;
