import React from "react";
import ReactGA from "react-ga";
import Home from "./components/Home";
import { Router, Switch, Route } from "react-router-dom";
import ErrorNotFound from "./components/ErrorNotFound";
import "./bootstrap.min.css";
import "./custom.css";
import "./css/footer.css";
//import createHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
import { getLocation } from "./services/geoService";

const location = process.env.REACT_APP_LOCATION;
const redirect = process.env.REACT_APP_REDIRECT || "true";
const home = "home";

const history = createBrowserHistory();
history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

class App extends React.Component {
    state = {
      checkingLocation: true
    }

    async componentDidMount() {
      if(redirect !== "false") {
        //await geoRedirect()
      }
    }

    render() {
        if(!isHome()) {
          return (
              <Router history={history}>
                  <div>
                      <Switch>
                          <Route exact path="/" component={Home} />
                          <Route component={ErrorNotFound} />
                      </Switch>
                  </div>
              </Router>
          );
        }

        return "Checking location..."

        /* else if(this.state.checkingLocation) {
          return <p>Checking location...</p>
        }
        else {
          return <p>Choose location</p>
        } */
    }
}

const isHome = () => {
  return location === home
}

const geoRedirect = async () => {
  ReactGA.pageview(window.location.pathname);
        const nearestLocation = await getLocation()
        console.log("Redirecting to", nearestLocation)
        window.location = `https://${nearestLocation}.devsjobs.com/`;
}

export default App;
