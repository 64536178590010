import React from "react";
import Filter from "./Filter";

const time24h = "24h";
const time3d = "3d";
const time7d = "7d";
const time30d = "30d";

class FilterPanel extends React.Component {
  getTimeFilterName = () => {
    if (this.props.currentTimeFilter === time24h) {
      return "Last 24h";
    }

    if (this.props.currentTimeFilter === time3d) {
      return "Last 3 days";
    }

    if (this.props.currentTimeFilter === time7d) {
      return "Last 7 days";
    }

    if (this.props.currentTimeFilter === time30d) {
      return "Last 30 days";
    }
  };

  

  render() {
    const {
      currentSearch,
      currentTimeFilter,
      currentSkillsFilter,
      currentCompanyFilter,
      onSearchFilterClose,
      onTimeFilterClose,
      onCompanyFilterClose,
      onSkillFilterClose,
      onClearFiltersClick,
      isAnyFilterApplied
    } = this.props;

    return (
      <div>
        {currentSearch && <Filter alertType="success" text={`'${currentSearch}'`} onClose={onSearchFilterClose} />}
        {currentTimeFilter && (
          <Filter
            alertType="primary"
            id={currentTimeFilter}
            text={this.getTimeFilterName()}
            onClose={onTimeFilterClose}
          />
        )}
        {currentSkillsFilter.map(skill => (
          <Filter alertType="info" text={skill} onClose={onSkillFilterClose} />
        ))}
        {currentCompanyFilter.map(company => (
          <Filter alertType="secondary" text={company} onClose={onCompanyFilterClose} />
        ))}
        {isAnyFilterApplied() && (
          <span>
            <button className="btn btn-link" onClick={onClearFiltersClick}>
              Clear all
            </button>
          </span>
        )}
      </div>
    );
  }
}

export default FilterPanel;
