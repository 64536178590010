import React, { Component } from 'react';

const location = process.env.REACT_APP_LOCATION

export default class ErrorNotFound extends Component {

    render() {
        return (
            <div className="container">
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
          <div className="container">
            <a className="navbar-brand" href="/">
              <h1 className="text-uppercase">{location}<span className="text-info">devs</span>jobs</h1>
            </a>
          </div>
        </nav>

        <div className="container">
          <div className="row">
              <div className="col">
              <h1 className="text-center">Page not found :(</h1>
              </div>
            
          </div>
        </div>
      </div>
        );
    }
}