import Fuse from "fuse.js";

export const search = (query, jobs) => {
    if (!query) {
        return jobs;
    }

    var options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["role", "location", "company", "skills"]
    };
    var fuse = new Fuse(jobs, options);
    var result = fuse.search(query);
    return result;
};