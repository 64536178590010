import React from "react";
import Cookies from 'universal-cookie';
import JobFilter from "./JobFilter";
import JobCard from "./JobCard";
import qs from "query-string";
import { search } from "../services/searchService";
import { getJobs } from "../services/apiService";

//import london from `../images/${process.env.REACT_APP_JUMBOTRON_IMAGE}`;
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import FilterPanel from "./FilterPanel";

const time24h = "24h";
const time3d = "3d";
const time7d = "7d";
const time30d = "30d";

const location = process.env.REACT_APP_LOCATION
const jumbotronImage = process.env.REACT_APP_JUMBOTRON_IMAGE
const jumbotronImageLink = process.env.REACT_APP_JUMBOTRON_IMAGE_LINK
const jumbotronImageName = process.env.REACT_APP_JUMBOTRON_IMAGE_NAME
const jumbotronImageAuthor = process.env.REACT_APP_JUMBOTRON_IMAGE_AUTHOR
const jumbotronImageAuthorLink = process.env.REACT_APP_JUMBOTRON_IMAGE_AUTHOR_LINK

TimeAgo.addLocale(en);

const timeAgo = new TimeAgo("en-US");

const cookies = new Cookies(null, { path: '/' });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jobs: [],
      searchQuery: "",
      filtersVisible: false,
      menu: false,
      error: false
    };
    this.companies = [];
    this.skills = [];
    this.currentCompanyFilter = [];
    this.currentSkillsFilter = [];
    this.currentSearch = "";
    this.currentTimeFilter = "";
    this.query = "";
  }

  sortById = (a, b) => {
    var x = a.id.toLowerCase();
    var y = b.id.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  lastVisit = () => {
    const lastVisit = cookies.get('lastVisitTime');
    const now = Date.now();
    if (lastVisit) {
      this.lastVisitDate = new Date(lastVisit)

      //const hoursSinceLastTime = Math.ceil((parseInt(lastVisit) - now) / 3600);
      //alert(`It's been ${hoursSinceLastTime} hour(s) since you last visited us.`);
    }
    cookies.set('lastVisitTime', now);
  }



  componentDidMount() {
    this.lastVisit()
    //let all = require("../data/all.json");
    //this.companies = all.companies;
    //this.skills = all.skills;
    //this.jobs = all.jobs;
    //this.companies = require("../data/companies.json");
    
    /* cars.sort(function(a, b){
          var x = a.type.toLowerCase();
          var y = b.type.toLowerCase();
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        });  */

    //this.skills = require("../data/skills.json");
    

    getJobs()
    .then(response => {
       
      this.jobs = response.data.jobs;
      this.companies = response.data.companies;
      this.skills = response.data.skills;

      //let jobs = this.jobs;
      
      this.skills.sort(this.sortById);
      this.companies.sort(this.sortById);
      this.jobs.sort((a, b) => new Date(b.created) - new Date(a.created));

      for(let job of this.jobs) {
        job.createdDate = new Date(job.created)
        //job.createdDate.setDate(job.createdDate.getDate() + 7);
        //console.log(job.createdDate)
        //console.log(this.lastVisitDate)
        job.isNew = job.createdDate > this.lastVisitDate
      }

      this.setState({ jobs: this.jobs });

      if (this.props.location.search) {
        this.handleNewQuery();
      }
    })
    .catch(error => {
      this.setState({error: true})
    })
    .finally(() => {
      this.setState({ loading: false })
    });
    //let jobs = require("../data/jobs.json");

    
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    if (locationChanged) {
      this.handleNewQuery();
    }
  }

  handleNewQuery = () => {
    var query = qs.parse(this.props.location.search, { comma: true });

    if (query.s || query.c || query.q || query.t) {
      this.currentCompanyFilter = query.c ? query.c.split(",") : [];
      this.currentSkillsFilter = query.s ? query.s.split(",") : [];
      this.currentSearch = query.q;
      this.currentTimeFilter = query.t;
    }

    this.updateResults();
  };

  handleChange = e => {
    e.preventDefault();

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleClick = e => {
    e.preventDefault();

    window.open(e.target.parentElement.dataset.joburl, "_blank");
  };

  handleCompaniesChanged = selectedCompanies => {
    this.currentCompanyFilter = selectedCompanies;
    this.updateQuery();
  };

  handleCompaniesChecked = company => {
    this.currentCompanyFilter.push(company);
    this.updateQuery();
  };

  handleCompaniesUnchecked = company => {
    var index = this.currentCompanyFilter.indexOf(company);
    if (index > -1) {
      this.currentCompanyFilter.splice(index, 1);
    }

    this.updateQuery();
  };

  handleSkillsChecked = skill => {
    this.currentSkillsFilter.push(skill);
    this.updateQuery();
  };

  handleSkillsUnchecked = skill => {
    var index = this.currentSkillsFilter.indexOf(skill);
    if (index > -1) {
      this.currentSkillsFilter.splice(index, 1);
    }

    this.updateQuery();
  };

  handleSkillsChanged = selectedSkills => {
    this.currentSkillsFilter = selectedSkills;
    this.updateQuery();
  };

  updateQuery = () => {
    var queryObject = {
      q: this.currentSearch || undefined,
      c: this.currentCompanyFilter,
      s: this.currentSkillsFilter,
      t: this.currentTimeFilter || undefined
    };

    const options = {
      arrayFormat: "comma",
      skipNulls: true
    };

    var query = qs.stringify(queryObject, options);
    this.props.history.push(`?${query}`);
  };

  timeFilter = (job, daysAgo) => {
    var created = new Date(job.created);

    var d = new Date();
    d.setDate(d.getDate() - daysAgo);
    return created > d;
  };

  updateResults = () => {
    let jobs = this.jobs;//require("../data/jobs.json");

    if (this.currentTimeFilter) {
      let timeFilter = job => this.timeFilter(job, 30);
      switch (this.currentTimeFilter) {
        case time24h:
          timeFilter = job => this.timeFilter(job, 1);
          break;
        case time3d:
          timeFilter = job => this.timeFilter(job, 3);
          break;
        case time7d:
          timeFilter = job => this.timeFilter(job, 7);
          break;
        case time30d:
          timeFilter = job => this.timeFilter(job, 30);
          break;
        default:
          break;
      }

      jobs = jobs.filter(timeFilter);
    }

    if (this.currentSearch) {
      jobs = search(this.currentSearch, jobs);
    }

    if (this.currentCompanyFilter.length > 0) {
      jobs = jobs.filter(job => this.currentCompanyFilter.includes(job.companyId));
    }

    if (this.currentSkillsFilter.length > 0) {
      jobs = jobs.filter(job => this.currentSkillsFilter.some(el => job.skillIds.includes(el)));
    }

    jobs.sort((a, b) => new Date(b.created) - new Date(a.created));

    this.setState({ jobs: jobs });
  };

  handleSearch = e => {
    e.preventDefault();

    this.currentSearch = this.state.searchQuery;
    this.updateQuery();
  };

  handleSearchFilterClose = () => {
    this.currentSearch = "";
    this.updateQuery();
  };

  handleCompanyFilterClose = company => {
    this.handleCompaniesUnchecked(company); //todo: change name of the function
  };

  handleSkillFilterClose = skill => {
    this.handleSkillsUnchecked(skill); //todo: change name of the function
  };

  handleTimeFilterClick = e => {
    e.preventDefault();
    this.currentTimeFilter = e.target.name;
    this.updateQuery();
  };

  handleTimeFilterClose = () => {
    this.currentTimeFilter = "";
    this.updateQuery();
  };

  handleLast3dClick = e => {
    e.preventDefault();
    this.currentTimeFilter = e.target.name;
    this.updateQuery();
  };

  keyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.currentSearch = this.state.searchQuery;
      this.updateQuery();
    }
  };

  handleSearchSubmit = event => {
    event.preventDefault();

    this.currentSearch = this.state.searchQuery;
    this.updateQuery();
  };

  handleFilterToggle = e => {
    e.preventDefault();

    const filtersVisible = !this.state.filtersVisible;
    this.setState({ filtersVisible });
  };

  handleClearFiltersClick = e => {
    e.preventDefault();

    this.currentCompanyFilter = [];
    this.currentSkillsFilter = [];
    this.currentSearch = "";
    this.currentTimeFilter = "";
    this.updateQuery();
  };

  isAnyFilterApplied = () => {
    return (
      this.currentCompanyFilter.length > 0 ||
      this.currentSkillsFilter.length > 0 ||
      this.currentSearch ||
      this.currentTimeFilter
    );
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu });
  };

  render() {
    const show = this.state.menu ? "show" : "";
    const { loading } = this.state;

    return (
      <>
        <div className="container">
          <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
            <div className="container">
              <a className="navbar-brand" href="/">
                <h1 className="text-uppercase">
                  {location}<span className="text-info">devs</span>jobs
                </h1>
              </a>
              <button
                className="navbar-toggler"
                aria-expanded="false"
                aria-controls="navbarColor01"
                aria-label="Toggle navigation"
                type="button"
                data-target="#navbarColor01"
                data-toggle="collapse"
                onClick={this.toggleMenu}
              >
                <span className="navbar-toggler-icon" />
              </button>

              <div className={"collapse navbar-collapse " + show} id="navbarColor01">
                <ul className="navbar-nav mr-auto"></ul>

                <form className="form-inline my-2 my-lg-0">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="company, role, skill..."
                    aria-label="Search"
                    name="searchQuery"
                    onChange={this.handleChange}
                    value={this.state.searchQuery}
                  />
                  <button
                    className="btn btn-outline-secondary my-2 my-sm-0"
                    type="submit"
                    onClick={this.handleSearchSubmit}
                  >
                    Search
                  </button>
                </form>
              </div>
            </div>
          </nav>

          <div className="container">
            <div className="row">
              <div className="jumbotron job-jumbotron text-center">
                <div className="view overlay my-4">
                  <img src={`/images/${jumbotronImage}`} className="img-fluid" alt={location} />
                </div>

                <h1 className="card-title mb-4">
                  software jobs in <span className="font-weight-bold text-primary"> #{location}</span>
                </h1>

                <h4 className="card-text">top companies | no agents</h4>
              </div>
            </div>
            <div className="row d-md-none">
              <button type="button" className="btn btn-primary" onClick={this.handleFilterToggle}>
                {this.state.filtersVisible ? "Hide filters" : "Show filters"}
              </button>
            </div>
            <div className={this.state.filtersVisible ? "row d-md-none" : "d-none"}>
              <div className="col">
                <h4>Posted</h4>
                <button className="btn btn-link" name={time24h} onClick={this.handleTimeFilterClick}>
                  Last 24 hours
                </button>
                <br />
                <button className="btn btn-link" name={time3d} onClick={this.handleTimeFilterClick}>
                  Last 3 days
                </button>
                <br />
                <button className="btn btn-link" name={time7d} onClick={this.handleTimeFilterClick}>
                  Last 7 days
                </button>
                <br />
                <button className="btn btn-link" name={time30d} onClick={this.handleTimeFilterClick}>
                  Last 30 days
                </button>
              </div>
              <div className="col">
                <JobFilter
                  header="Skills"
                  items={this.skills}
                  selectedItems={this.currentSkillsFilter}
                  checked={this.handleSkillsChecked}
                  unchecked={this.handleSkillsUnchecked}
                />
              </div>
              <div className="col">
                <JobFilter
                  header="Companies"
                  items={this.companies}
                  selectedItems={this.currentCompanyFilter}
                  checked={this.handleCompaniesChecked}
                  unchecked={this.handleCompaniesUnchecked}
                />
              </div>
            </div>
            <div className={this.state.filtersVisible ? "row" : "d-none"}>
              <button type="button" className="btn btn-primary" onClick={this.handleFilterToggle}>
                Hide filters
              </button>
            </div>
            <div className="row job-filter-row">
              <div className="col-md-2 d-none d-md-block">
                <h4>Posted</h4>
                <button
                  className="btn btn-link time-filter-btn-link"
                  name={time24h}
                  onClick={this.handleTimeFilterClick}
                >
                  Last 24 hours
                </button>
                <br />
                <button
                  className="btn btn-link time-filter-btn-link"
                  name={time3d}
                  onClick={this.handleTimeFilterClick}
                >
                  Last 3 days
                </button>
                <br />
                <button
                  className="btn btn-link time-filter-btn-link"
                  name={time7d}
                  onClick={this.handleTimeFilterClick}
                >
                  Last 7 days
                </button>
                <br />
                <button
                  className="btn btn-link time-filter-btn-link"
                  name={time30d}
                  onClick={this.handleTimeFilterClick}
                >
                  Last 30 days
                </button>
                <br />
                <br />
                <JobFilter
                  header="Skills"
                  items={this.skills}
                  selectedItems={this.currentSkillsFilter}
                  checked={this.handleSkillsChecked}
                  unchecked={this.handleSkillsUnchecked}
                />
                <JobFilter
                  header="Companies"
                  items={this.companies}
                  selectedItems={this.currentCompanyFilter}
                  checked={this.handleCompaniesChecked}
                  unchecked={this.handleCompaniesUnchecked}
                />
              </div>
              <div className="col-md-10 col-sm-12">
                <FilterPanel
                  currentSearch={this.currentSearch}
                  currentTimeFilter={this.currentTimeFilter}
                  currentSkillsFilter={this.currentSkillsFilter}
                  currentCompanyFilter={this.currentCompanyFilter}
                  onSearchFilterClose={this.handleSearchFilterClose}
                  onTimeFilterClose={this.handleTimeFilterClose}
                  onCompanyFilterClose={this.handleCompanyFilterClose}
                  onSkillFilterClose={this.handleSkillFilterClose}
                  onClearFiltersClick={this.handleClearFiltersClick}
                  isAnyFilterApplied={this.isAnyFilterApplied}
                />

                <div className="card-columns-old row">
                  {this.state.jobs.length > 0 &&
                    this.state.jobs.map(job => (
                      <div className="col-lg-4 col-sm-6 job-card-div">
                        <JobCard key={job.id} {...job} timeAgo={timeAgo} />
                      </div>
                    ))}
                </div>
                
                {!loading && this.state.jobs.length === 0 && !this.state.error && <p className="text-center">No results for applied filters</p>}

                {loading && <p className="text-center">Finding dream jobs...</p>}

                {this.state.error && <p className="text-center">Something went wrong</p>}
              </div>
            </div>
          </div>
        </div>

        <footer class="footer mt-auto py-3">
          <div class="container">
            <span class="text-muted">
              {location.toUpperCase()}<strong> DEVS </strong>JOBS {/* <br/> Contact */} <br/> Photo credit:{" "}
              <a href={jumbotronImageLink}>{jumbotronImageName}</a> by{" "}
              <a href={jumbotronImageAuthorLink}>{jumbotronImageAuthor}</a> (CC BY-SA 2.0) 
            </span>
          </div>
        </footer>
      </>
    );
  }
}

export default Home;
