import React from "react";

class Filter extends React.Component {
    handleClose = e => {
        e.preventDefault();

        if(this.props.onClose) {
            this.props.onClose(this.props.text);
        }
    };

    render() {
        const alertType = `alert-${this.props.alertType}`;

      return (<div className={"alert fade show filter-alert " + alertType} role="alert">
      <strong>{this.props.text}</strong>
          <button type="button" className="close filter-close" aria-label="Close" onClick={this.handleClose}>
          <span aria-hidden="true">&times;</span>
      </button>
      </div>);
    }
  }

  export default Filter;
